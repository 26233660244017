/**
 * Owl carousel slider class.
 */
import 'owl.carousel';

import { Component } from '../components/component';

const defaultSetting = {
    loop: true,
    margin: 0,
    items: 1,
    nav: true,
    autoPlay: true,
    stopOnHover: true,
    navText: ['', ''],
};
/**
 * Class for init slider.
 *
 */
export class Slider extends Component{
    /**
     * @param el{string} - slider class.
     * @param btnPrev{string} - slider class prev.
     * @param btnNext{string} - slider class next.
     * @param settings{Object} - settings.
     * @param plugin{string} - plugin define.
     */
    constructor(el = '.js_owl-carousel', btnPrev, btnNext, countSlides, settings = defaultSetting, plugin = 'owlCarousel'){
        super(el);
        this.settings = Object.assign({}, defaultSetting, settings);
        this.plugin = plugin;
        if(!this.errors) {
            if(countSlides){
                this.settings.onInitialized = this.counterSlides
                this.settings.onChanged = this.counterSlides
            }
            this.inetedPlugin = this.initSlider();
            this.newBtn(this.inetedPlugin, btnNext, btnPrev);
            this.getElement(this.inetedPlugin);
            this.onChange(this.inetedPlugin);
        }
    }

    getElement(owl) {
        owl.find('.owl-item.active').last().addClass('__last')
        owl.find('.owl-item.active').first().prev().addClass('__last')
    }

    onChange(owl) {
        owl.on('changed.owl.carousel', () => {
            setTimeout(() => {
                owl.find('.owl-item').removeClass('__last')
                this.getElement(owl)
            }, 100)
        })
    }

    counterSlides(event) {
        if (!event.namespace) {
            return;
        }
        let slides = event.relatedTarget;
        $('.js_slider-counter').text(slides.relative(slides.current()) + 1 + ' / ' + slides.items().length);
    }

    newBtn(owl, btnNext, btnPrev){
        // Go to the next item
        $(btnNext).click(function(e) {
            e.preventDefault()
            owl.trigger('next.owl.carousel');
        });
        // Go to the previous item
        $(btnPrev).click(function(e) {
            e.preventDefault()
            owl.trigger('prev.owl.carousel', [300]);
        });
    }

    /**
     * Set new plugin.
     *
     * @param plugin{string} - name of function which init plugin.
     */
    set plugin(plugin){
        plugin = jQuery()[plugin];
        if(plugin == undefined){
            console.error('Error in Slider: plugin of slider is not defined.');
            return;
        }
        this.sliderPlugin = plugin;
    }

    /**
     * Initialize slider with owlCarousel.
     */
    initSlider(){
        return this.sliderPlugin.call(this.element, this.settings);
    }
}