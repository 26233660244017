import Field from './field';
import '../spinner';
import { getToken } from '../getToken';

/**
 * @param formClass {string} - class of form.
 * @param settings {Object} - settings object.
 * @param reference {Object} - reference for validation.
 */

export default class Form{
    constructor(formEL, settings, reference) {
        this.form = formEL;

        if(typeof this.form == "string") return;

        if(this.form == null || undefined) return;
        this.inputs = Array.from(this.form.querySelectorAll('input:not([type="hidden"]), select, textarea'));
        /**
         * Set action(url for request)
         */
        let action = this.form.getAttribute('action');

        this.action = action != null ? action  : '/';

        // Form state if it contain errors
        this.state = true;
        //determine if there are any mistakes now.
        this.error = false;
        // Show spunner activity
        this.isSpinnerActive = false;
        // Text of status field.
        this.statusText = null;
        // Contain all field of this form
        this.items = [];
        // Contain errors field with position
        this.errorItems = {};
        // settings
        let customSettings = {
            resetAfterSubmit: true,
            onlyValidate: false,
            statusId: 'form-status',
            statusDefaultClass: 'form-status-blk',
            statusErrorClass:'with_error',
            statusSuccessClass : 'with_success',
            errorClass: 'error',
            successClass: 'success',
            validateClass: '.js_sendform-validate',
            requiredClass: 'form-required',
            modalOpen: true,
            modalId: '#thanks',
            msgSend: '',
            msgDone: 'This form has been successfully sent',
            msgError: 'Sorry, cannot send the form',
            msgValError: 'One of the fields is empty',
            msgValEachField : false,
            msgValErrorField: '',
            msgValDoneField: '',
            spinnerColor: '#000',
            formPosition: 'relative',
            resetClass: '.js_senform-reset',
            method: 'POST',
            success: data => {
                this.successSubmit();
            },
            error: data => {
                this.errorSubmit();
            },
            validationSuccess : () => {},
            validationError : () => {
                this.validationErrorCallback();
            }
        };
        // validation rules
        let customReference = {
            email: ['isEmail', 'isEmpty'],
            text: ['isEmpty'],
            textarea: ['isEmpty'],
            phone: ['minLength'],
            required:['isEmpty'],
            checkbox:['isChecked'],
            radio:['isCheckedRadio']
        };

        this.settings = Object.assign(customSettings ,settings);
        this.reference = Object.assign(customReference, reference);

        this.onInit();
    }

    /**
     * On initialize class.
     * Creating all inputs of this form.
     * if setting for only validate true init this func.
     * else init function on submitting.
     * creating status text field.
     * init function for reset field.
     */
    onInit(){
        this.createInputsValidate();

        if(this.settings.onlyValidate){
            this.onValidate();
        }
        else{
            this.form.addEventListener('submit', event => {
                event.preventDefault();
                if(!$(this.form).find('.js_input-val').prop('checked')){
                    this.preSubmit();
                }
            });
        }
        this.createStatusField();
        this.onReset();
    }

    /**
     * Creating for each input, select, checkboxes own class.
     * And pushing this classes into array.
     */
    createInputsValidate(){
        let eventChangeStatusInput = document.createEvent('Event');
        eventChangeStatusInput.initEvent('changeStatusInput', true, true);
        document.addEventListener('changeStatusInput',  (e) => {
            this.validateField();
        }, false);

        this.inputs.forEach((el, i)=>{
            let item = new Field(el, eventChangeStatusInput, this.reference, this.settings, i);
            this.items.push(item);
        });
    }
    /**
     * Create hidden field for status text.
     */
    createStatusField(){
        var div = document.createElement('div');
        div.innerHTML = '&nbsp;';
        div.id= this.settings.statusId;
        this.form.appendChild(div);
        this.statusText = this.form.querySelector(`#${this.settings.statusId}`);
        this.statusText.className += ` ${this.settings.statusDefaultClass}`;
    }

    /**
     * checking on error.
     * prepare for submitting:
     * add spinner, add status text.
     * call submit function.
     */
    preSubmit(){
        this.validateField();
        if(!this.state){
            this.errorOnForm();
            return;
        }

        this.error = false;
        if(!this.isSpinnerActive) this.addSpinner();
        this.statusText.innerHTML = this.settings.msgSend;
        this.submitData();
    }

    /**
     * Foreach in all items call validation function.
     * @param result {object} - variable keep return from
     * validation function.Object contain 2 attr
     * result.valid {boolean} -show is field pass validation.
     * result.position {string} - position of field.
     *
     */
    validateField(){
        let localState = true;
        this.items.forEach((item)=>{
            let result = item.validate();
            if (result == undefined) return;
            localState = localState * result.valid;

            if(localState){
                delete this.errorItems[result.position];
            }
            else{
                this.errorItems[result.position] = false;
            }
        });

        this.state = localState;
        if(this.state){
            this.removeStatusText();
        }
    }

    /**
     * Call reset method on all items.
     */
    resetField(){
        this.items.forEach((item)=>{
            item.resetSelf();
        });
    }

    /**
     * Adding spinner.
     */
    addSpinner(){
        $.spinnerAdd('formsendHover');
        // var div = document.createElement('div');
        // div.innerHTML= '<div class="form-loading"></div>';
        // div.id= 'formsendHover';
        // this.form.appendChild(div);
        // this.isSpinnerActive = true;
    }

    /**
     * Removing spinner.
     */
    removeSpinner(){
        $.spinnerRemove('formsendHover');
        // document.querySelector('#formsendHover').remove();
        // this.isSpinnerActive = false;
    }

    /**
     * init validation by press on btn.
     */
    onValidate(){
        let validateBtn = this.form.querySelector(this.settings.validateClass);
        validateBtn.addEventListener('click', (event)=>{
            event.preventDefault();
            this.validateField();
            if(this.state){
                this.settings.validationSuccess();
                return;
            }
            this.settings.validationError();
        });
    }

    /**
     * init function reseting by press btn.
     */
    onReset(){
        let resetClass = this.form.querySelector(this.settings.resetClass);
        if(resetClass == null || undefined) return;
        resetClass.addEventListener('click', ()=>{
            this.resetField();
        });
    }

    /**
     * Add text and set error on true.
     * And add text error.
     */
    errorOnForm(){
        if(this.error) return;
        this.error = true;
        this.statusText.innerHTML = this.settings.msgValError;
        // this.statusText.className = ' with_error';
        this.errorStatusClass();
    }

    /**
     * On error validation
     */
    validationErrorCallback(){
        this.errorStatusClass();
        this.printText(this.settings.msgValError);
        console.log('error')
    }

    /**
     * Set text in status in form.
     * @param text{string}
     */
    printText(text){
        this.statusText.innerHTML = text;
    }

    /**
     * Clean status text
     */
    removeStatusText(){
        this.statusText.innerHTML = '';
        this.statusText.className = ` ${this.settings.statusDefaultClass}`;
    }

    /**
     * Set error class on status text in form
     */
    errorStatusClass(){
        this.statusText.className += ` ${this.settings.statusErrorClass}`;
    }

    /**
     * Set success class on status text in form
     */
    successStatusClass(){
        this.statusText.className += ` ${this.settings.statusSuccessClass}`;
    }

    /**
     * Submitting data
     * @param event
     */
    submitData(event){
        let request = new XMLHttpRequest();
        request.open(this.settings.method, this.action , true);
        let token = getToken();
        let data = new FormData(this.form);
        data.append('_token', `${token}`);
        request.onload = data => {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                this.settings.success(data);
            } else {
                // We reached our target server, but it returned an error
                this.settings.error();
            }
            this.removeSpinner();
        };
        request.send(data);
    }

    /**
     * On error submit
     */
    errorSubmit(){
        this.errorStatusClass();
        this.printText(this.settings.msgError);
    }

    /**
     * On success submit
     */
    successSubmit(){
        this.successStatusClass();
        this.printText(this.settings.msgDone);
        let _this = this;
        if (this.settings.resetAfterSubmit) {
            _this.resetForm(_this.form);
        }
    }
    //Form reset
    resetForm(input_form) {
        var form = $(input_form);
        form.find('input[type=text],input[type=tel],input[type=email],textarea').val('');
        form.find('input:checkbox, input:radio').removeAttr('checked');
    }

}
